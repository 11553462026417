export default class Dropdown {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({}, options);
        this.init();
    }
    init() {

        for (const element of this.selector.children) {
            if (element.tagName == 'BUTTON') {
                element.addEventListener('click', () => {
                    this.toggle(element.nextElementSibling);
                })
            }
        }

        // si je clique a l'exterieur de mon dropdown, je ferme le dropdown
        document.addEventListener('click', (e) => {
            if (!this.selector.contains(e.target)) {
                this.selector.children[0].nextElementSibling.classList.remove('open')
            }
        })
    }

    toggle = (ele) => {
        ele?.classList.toggle('open');
    }
}
