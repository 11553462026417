export default class AudioPlayer {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign(
            {
                audioFile: "https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3",
                audioName: "Nom du son audio",
            },
            options,
        );
        this.init();
    }
    init() {

        function createElement(className) {
            const element = document.createElement('div')

            // si className est un tableau
            if (Array.isArray(className)) {
                className.forEach(value => {
                    element.classList.add(value)
                })
            } else {
                element.classList.add(className)
            }
            return element;
        }

        const audioPlayer = this.selector;
        const selector = this.selector.dataset.audio;
        const audio = new Audio(this.options.audioFile);

        // console.dir(audio);

        audioPlayer.appendChild(createElement("timeline"));
        document.querySelector(`${selector} .timeline`).appendChild(createElement("progress"));

        audioPlayer.appendChild(createElement("controls"));
        document.querySelector(`${selector} .controls`).appendChild(createElement("play-container"));
        document.querySelector(`${selector} .play-container`).appendChild(createElement(["toggle-play", "play"]));
        document.querySelector(`${selector} .controls`).appendChild(createElement("time"));
        document.querySelector(`${selector} .time`).appendChild(createElement("current"));
        document.querySelector(`${selector} .current`).textContent = "0:00";
        document.querySelector(`${selector} .time`).appendChild(createElement("divider"));
        document.querySelector(`${selector} .time`).appendChild(createElement("length"));
        document.querySelector(`${selector} .controls`).appendChild(createElement("name"));
        document.querySelector(`${selector} .name`).textContent = this.options.audioName;
        document.querySelector(`${selector} .controls`).appendChild(createElement("volume-container"));
        document.querySelector(`${selector} .volume-container`).appendChild(createElement("volume-button"));
        document.querySelector(`${selector} .volume-button`).appendChild(createElement(["volume", "fa-xl", "fa-solid", "fa-volume-high" ]));
        document.querySelector(`${selector} .volume-container`).appendChild(createElement("volume-slider"));
        document.querySelector(`${selector} .volume-slider`).appendChild(createElement("volume-percentage"));


        audio.addEventListener(
            'loadeddata',
            () => {
                audioPlayer.querySelector('.time .length').textContent = getTimeCodeFromNum(audio.duration);
                audio.volume = 0.75;
            },
            false,
        );

        //click on timeline to skip around
        const timeline = audioPlayer.querySelector('.timeline');
        timeline.addEventListener(
            'click',
            (e) => {
                const timelineWidth = window.getComputedStyle(timeline).width;
                const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * audio.duration;
                audio.currentTime = timeToSeek;
            },
            false,
        );

        //click volume slider to change volume
        const volumeSlider = audioPlayer.querySelector('.controls .volume-slider');
        volumeSlider.addEventListener(
            'click',
            (e) => {
                const sliderWidth = window.getComputedStyle(volumeSlider).width;
                const newVolume = e.offsetX / parseInt(sliderWidth);
                audio.volume = newVolume;
                audioPlayer.querySelector('.controls .volume-percentage').style.width = newVolume * 100 + '%';
            },
            false,
        );

        //check audio percentage and update time accordingly
        setInterval(() => {
            const progressBar = audioPlayer.querySelector('.progress');
            progressBar.style.width = (audio.currentTime / audio.duration) * 100 + '%';
            audioPlayer.querySelector('.time .current').textContent = getTimeCodeFromNum(audio.currentTime);
        }, 100);

        //toggle between playing and pausing on button click
        const playBtn = audioPlayer.querySelector('.controls .toggle-play');
        playBtn.addEventListener(
            'click',
            () => {
                if (audio.paused) {
                    playBtn.classList.remove('play');
                    playBtn.classList.add('pause');
                    audio.play();
                } else {
                    playBtn.classList.remove('pause');
                    playBtn.classList.add('play');
                    audio.pause();
                }
            },
            false,
        );

        audioPlayer.querySelector('.volume-button').addEventListener('click', () => {
            const volumeEl = audioPlayer.querySelector('.volume-container .volume');
            audio.muted = !audio.muted;
            if (audio.muted) {
                volumeEl.classList.remove('icono-volumeMedium');
                volumeEl.classList.add('icono-volumeMute');
            } else {
                volumeEl.classList.add('icono-volumeMedium');
                volumeEl.classList.remove('icono-volumeMute');
            }
        });

        //turn 128 seconds into 2:08
        function getTimeCodeFromNum(num) {
            let seconds = parseInt(num);
            let minutes = parseInt(seconds / 60);
            seconds -= minutes * 60;
            const hours = parseInt(minutes / 60);
            minutes -= hours * 60;

            if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
            return `${String(hours).padStart(2, 0)}:${minutes}:${String(seconds % 60).padStart(2, 0)}`;
        }
    }
}
