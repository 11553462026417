import Tingle from "tingle.js";

export default class Modal {
    constructor(selector, btn, options = {}, footerSelector = null) {
        this.selector = selector;
        this.btn = btn;
        this.footerSelector = footerSelector;
        this.options = Object.assign(
            {
                closeMethods: ['overlay', 'button', 'escape'],
                footer: false,
                stickyFooter: false,
                closeLabel: 'Fermer',
                cssClass: [],
                cross: 'default',
                onClose: () => {},
                onOpen: () => {},
                beforeOpen: () => {},
                beforeClose: () => {
                    return true;
                },
            },
            options,
        );
        this.init();
    }
    init() {
        const options = this.options;
        const modal = new Tingle.modal({
            closeMethods: this.options.closeMethods,
            footer: this.options.footer,
            stickyFooter: this.options.stickyFooter,
            closeLabel: this.options.closeLabel,
            cssClass: this.options.cssClass,
            onClose: this.options.onClose,
            onOpen: this.options.onOpen,
            beforeOpen: this.options.beforeOpen,
            beforeClose: this.options.beforeClose,
        });

        this.btn.addEventListener('click', function () {
            modal.open();
        });

        modal.setContent(this.selector.innerHTML);

        if (options.footer === true) {
            modal.modalBoxFooter.insertAdjacentHTML('beforeend', this.footerSelector.innerHTML);
        }

        if (options.cross === 'inside') {
            modal.modalCloseBtn.classList.add('inside');
            const closeBtn = modal.modalCloseBtn.querySelector('.tingle-modal__closeIcon');
            const closeLabel = modal.modalCloseBtn.querySelector('.tingle-modal__closeLabel');
            closeBtn.classList.add('modal__close-icon');
            closeLabel.classList.add('modal__close-label');
            options.beforeOpen(modal.modalBoxContent.appendChild(modal.modalCloseBtn));
        }

        if (modal.modalBoxFooter && modal.modalBoxFooter.querySelector('.button__close')) {
            const closeBtns = modal.modalBoxFooter.querySelectorAll('.button__close');
            closeBtns.forEach((element) => {
                element.addEventListener('click', () => {
                    modal.close();
                });
            });
        }
    }
}
