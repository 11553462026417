import Hamburger from "./Hamburger.js";

document.querySelectorAll("[data-hamburger]").forEach((element) => {
    const value = element.dataset["hamburger"];

    switch (value) {
        default:
            new Hamburger(element, {
                class: 'is-active',
            });
            break;
    }
});
