import './components/validator.js'
import './components/backTop.js'
import '../../sassy/component/hamburger/js/init.js'
import './components/slider.js'
import '../../sassy/component/dropdown/js/init.js'
import '../../sassy/component/select/js/init.js'
import '../../sassy/component/video/js/init.js'
import './components/modal.js'
import './components/map.js'
import './components/menu.js'
import '../../sassy/component/audio/js/init.js'



function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // couper les espaces blancs en début et en fin d'ouvrage
    str = str.toLowerCase(); // convertir la chaîne en minuscules
    str = str.replace(/[^a-z0-9 -]/g, '') // supprimer les caractères non alphanumériques
             .replace(/\s+/g, '-') // remplacer les espaces par des traits d'union
             .replace(/-+/g, '-'); // supprimer les traits d'union consécutifs
    return str;
}

function isCurrentPage(items) {
    const pathUrl = window.location.pathname.split('/').pop()
    items.forEach(function (item) {
        const path = slugify(item.innerText.toLowerCase())
        if (path == pathUrl) {
            item.classList.add("active");
        }
    })
}

isCurrentPage(document.querySelectorAll('.menu__item'))

document.querySelector('html').classList.add(window.location.pathname.split('/').pop())
