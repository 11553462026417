import Plyr from 'plyr';

export default class Video {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({});
        this.init();
    }

    init() {

        if (this.selector.dataset['video'] === 'plyr') {
            new Plyr(this.selector.lastElementChild, this.options);
        }

    }
}
