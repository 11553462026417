export default class Hamburger {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({
            class: 'is-active',
        }, options);
        this.init();
    }
    init() {
        const customClass = this.options.class;
        this.selector.addEventListener("click", function () {
            this.classList.toggle(customClass);
        }, false);
    }
}


