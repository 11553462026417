import Video from './Video';

window.Video = Video;
globalThis.Video = Video;
document.querySelectorAll('[data-video]').forEach((element) => {
    const value = element.dataset['video'];

    switch (value) {
        case 'plyr':
            new Video(element);
            break;

        default:
            break;
    }
});
