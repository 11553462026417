import Modal from "../../../sassy/component/modal/js/Modal.js";

document.querySelectorAll("[data-modal]").forEach((element) => {
    const value = element.dataset["modal"];
    const idBtn = element.dataset["modalBtn"];
    const idFooter = element.dataset["modalFooter"];
    const btnSelector = document.querySelector(`#${idBtn}`);
    const footerSelector = document.querySelector(`#${idFooter}`);

    switch (value) {

        case 'modal-buttons':
            new Modal(element, btnSelector, {
                cross: 'inside',
            }, footerSelector);
            break;

        default:
            new Modal(element, btnSelector);
            break;
    }
});
