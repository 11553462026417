import JustValidate from 'just-validate';
import trads from './validator-trad.js'

window.JustValidate = JustValidate;
globalThis.JustValidate = JustValidate;

document.querySelectorAll(`[data-form]`).forEach((element) => {

    const validator = new JustValidate(element, undefined, trads);

    validator

        .addField('#control_firstName', [
            {
                rule: 'required',
                errorMessage: 'Field is required',
            },
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Field is too short',
            },
            {
                rule: 'maxLength',
                value: 40,
                errorMessage: 'Field is too long',
            },
        ], {
            errorsContainer: '#control_firstName-errors-container'
        }
        )
        .addField('#control_name', [
            {
                rule: 'required',
                errorMessage: 'Field is required',
            },
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Field is too short',
            },
            {
                rule: 'maxLength',
                value: 40,
                errorMessage: 'Field is too long',
            },
        ], {
            errorsContainer: '#control_name-errors-container'
        })

        .addField('#control_message', [
            {
                rule: 'required',
                errorMessage: 'Field is required',
            },
            {
                rule: 'minLength',
                value: 10,
                errorMessage: 'Field is too short',
            },
        ], {
            errorsContainer: '#control_message-errors-container'
        })
        .addField('#control_phoneNumber', [
            {
                rule: 'required',
                errorMessage: 'Field is required',
            },
            {
                rule: 'customRegexp',
                value: /^(0|\+33)[1-9]( *[0-9]{2}){4}$/,
                errorMessage: 'Value is incorrect',
            },
        ], {
            errorsContainer: '#control_phoneNumber-errors-container'
        })
        .addField('#control_email', [
            {
                rule: 'required',
                errorMessage: 'Field is required',
            },
            {
                rule: 'email',
                errorMessage: 'Is not email',
            },
        ], {
            errorsContainer: '#control_email-errors-container'
        })
        .addField('#control_consent_checkbox', [
            {
                rule: 'required',
                errorMessage: 'Is required',
            }
        ], {
            errorsContainer: '#control_consent_checkbox-errors-container'
        });


    const locals = {
        'fr-FR': 'French',
        'es-ES': 'Spanish',
    }

    if (navigator.language in locals) {
        validator.setCurrentLocale(locals[navigator.language]);
    }
});
