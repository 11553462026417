import Select from "./Select";

document.querySelectorAll("[data-select]").forEach((element) => {
    const value = element.dataset["select"];

    switch (value) {
        case "test":
            console.log(`hello ${value}`);
            break;

        case "languages":
            new Select(element, {
                showSearch: false,
                hideSelected: false,
            });
            break;

        default:
            new Select(element);
            break;
    }
});
