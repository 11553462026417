import AudioPlayer from "./Audio.js";

window.AudioPlayer = AudioPlayer;
globalThis.AudioPlayer = AudioPlayer;

document.querySelectorAll("[data-audio]").forEach((element) => {
    const value = element.dataset["audio"];
    const audioFile = element.dataset["audioFile"];
    const audioName = element.dataset["audioName"];

    switch (value) {
        default:
            new AudioPlayer(element,{
                audioFile: audioFile,
                audioName: audioName
            });
            break;
    }
});
