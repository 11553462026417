if (document.querySelector('.wrapper-menu')) {

    const element = document.querySelector('main')
    const selector = document.querySelector('.wrapper-menu')
    let value = selector.clientHeight
    element.style.marginTop = value + 'px'

    function updateSize() {
        // Si la largeur interne de la fenêtre est inférieure ou égale à 767 pixels,
        if ( window.innerWidth <= 767) {
            // Mise à jour la valeur de "value" à la hauteur actuelle de l'élément "selector" et définit le style margin-top de l'élément principal à la valeur mise à jour de "value"
            value =  selector.clientHeight
            element.style.marginTop = value + 'px'
            // Sinon, définit le style margin-top de l'élément principal à 0 pixels
        } else {
            element.style.marginTop =  '0px'
        }
    }

    // Mise à jour de la valeur lorsque la fenêtre est redimensionnée
    window.addEventListener('resize', updateSize);
    updateSize();
}
