import BackTop from "../../../sassy/component/backTop/js/BackTop";

document.querySelectorAll("[data-backTop]").forEach((element) => {
    const value = element.dataset["backTop"];

    switch (value) {
        case "test":
            console.log(`hello ${value}`);
            break;

        default:
            new BackTop(element);
            break;
    }
});
