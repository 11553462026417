const trads = [
    {
        key: 'Is required',
        dict: {
            Spanish: 'Es obligatorio',
            French: "Validation obligatoire"
        }
    },
    {
        key: 'Is not email',
        dict: {
            Spanish: 'No es un correo electronico',
            French: "Ce n'est pas un e-mail valide"
        }
    },
    {
        key: 'Value is incorrect',
        dict: {
            Spanish: 'El valor es incorrecto',
            French: 'La valeur est incorrecte'
        }
    },
    {
        key: 'Field is required',
        dict: {
            Spanish: 'El campo es obligatorio',
            French: 'Le champ est requis'
        }
    },
    {
        key: 'Field is too short',
        dict: {
            Spanish: 'El campo es demasiado corto',
            French: 'Le champ est trop court'
        }
    },
    {
        key: 'Field is too long',
        dict: {
            Spanish: 'El campo es demasiado largo',
            French: 'Le champ est trop long'
        }
    },
    {
        key: 'Email is required',
        dict: {
            Spanish: 'Correo electronico es requerido',
            French: "L'e-mail est requis"
        }
    },
    {
        key: 'Email is invalid',
        dict: {
            Spanish: 'El correo electrónico es invalido',
            French: "L'e-mail est invalide"
        }
    }
];


export default trads
