import Slider from '../../../sassy/component/slider/js/Sliders';

window.Slider = Slider;
globalThis.Slider = Slider;
document.querySelectorAll(`[data-slider]`).forEach((element) => {
    const value = element.dataset['slider'];

    switch (value) {
        case 'hide':
            new Slider(element, {
                autoplay: true,
                pagination: false,
                arrows: false,
            });
            break;
        case 'hide-arrows':
            new Slider(element, {
                autoplay: true,
                arrows: false,
                classes: {
                    pagination: 'pagination pagination--slash',
                    page: 'pagination__item splide__pagination__page',
                }
            });
            break;
        case 'products':
            new Slider(element, {
                autoplay: true,
                perPage: 3,
                focus: 'fade',
                type: 'slide',
                classes: {
                    pagination: 'pagination pagination--slash',
                    page: 'pagination__item splide__pagination__page',
                },
                rewind: true,
                breakpoints: {
                    640 : { perPage: 1, padding: '5rem', },
                },
            });
            break;

        default:
            new Slider(element, {
                autoplay: true,
                classes: {
                    prev: 'splide__arrow--prev icons icons-arrow_left text-2xl',
                    next: "splide__arrow--next icons icons-arrow_right text-2xl",
                    pagination: 'pagination pagination--slash',
                    page: 'pagination__item splide__pagination__page',
                }
            });
            break;
    }
});
